<template>
    <Form @keydown.enter="$event.preventDefault()" @submit="handleSubmit" :validation-schema="schema" v-slot="{ errors }" ref="primary-location-form">
        <div class="modal" v-if="modelValue" :class="{ active : modelValue }">
            <div class="modal_container">
                <div class="modal_header border-bottom">
                    <h1>{{ user.company_id ? 'Edit Account' : 'Edit Primary Account' }}</h1>
                    <button class="close_btn left_out" @click="closeModal()">
                        <i class="fas fa-long-arrow-alt-right"></i>
                    </button>
                    <ul class="tab_sec">
                        <li @click="tab = 1" :class="{ active: tab === 1 }">Details</li>
                        <li @click="tab = 2" :class="{ active: tab === 2 }">Settings</li>
                        <li @click="tab = 3" :class="{ active: tab === 3 }">Advanced</li>
                    </ul>
                </div>
                <div class="modal_body">
                    <div class="tab_content">
                        <div class="setting_wpr" v-show="tab == 1">
                            <div class="upload_image">
                                <image-upload v-model="form.photo" image-type="public-logo" upload-text="Logo" :is-avatar="true" />
                            </div>
                            <div class="form_grp mb-1">
                                <div class="group_item">
                                    <label class="input_label">Location Name</label>
                                    <div class="field_wpr" :class="{ 'has-error': errors.businessname }">
                                        <Field autocomplete="nofill" name="businessname" v-model="form.businessname" type="text" placeholder="Onboardme" />
                                    </div>
                                    <ErrorMessage name="businessname" class="text-danger" />
                                </div>
                            </div>
                            <div class="form_grp mb-1">
                                <div class="group_item">
                                    <label class="input_label">Owner First Name</label>
                                    <div class="field_wpr" :class="{ 'has-error': errors.fname }">
                                        <Field autocomplete="nofill" name="fname" v-model="form.fname" type="text" placeholder="Robert" />
                                    </div>
                                    <ErrorMessage name="fname" class="text-danger" />
                                </div>
                                <div class="group_item">
                                    <label class="input_label">Owner Last Name</label>
                                    <div class="field_wpr" :class="{ 'has-error': errors.lname }">
                                        <Field autocomplete="nofill" name="lname" v-model="form.lname" type="text" placeholder="Hank" />
                                    </div>
                                    <ErrorMessage name="lname" class="text-danger" />
                                </div>
                            </div>
                            <div class="form_grp mb-1">
                                <div class="group_item">
                                    <label class="input_label">Address</label>
                                    <div class="field_wpr" :class="{ 'has-error': errors.address }">
                                        <Field autocomplete="nofill" name="address" v-model="form.address" type="text" placeholder="286 Whitehill Street" />
                                    </div>
                                    <ErrorMessage name="address" class="text-danger" />
                                </div>
                            </div>
                            <div class="form_grp mb-1">
                                <div class="group_item">
                                    <label class="input_label">City</label>
                                    <div class="field_wpr" :class="{ 'has-error': errors.city }">
                                        <Field autocomplete="nofill" name="city" v-model="form.city" type="text" placeholder="Lemont" />
                                    </div>
                                    <ErrorMessage name="city" class="text-danger" />
                                </div>
                                <div class="group_item">
                                    <label class="input_label">State/Province</label>
                                    <div class="field_wpr" :class="{ 'has-error': errors.state }">
                                        <Field autocomplete="nofill" name="state" v-model="form.state" type="text" placeholder="PA" />
                                    </div>
                                    <ErrorMessage name="state" class="text-danger" />
                                </div>
                            </div>
                            <div class="form_grp mb-1">
                                <div class="group_item">
                                    <label class="input_label">Zip/Postal Code</label>
                                    <div class="field_wpr" :class="{ 'has-error': errors.zipcode }">
                                        <Field autocomplete="nofill" name="zipcode" v-model="form.zipcode" type="text" placeholder="16851" />
                                    </div>
                                    <ErrorMessage name="zipcode" class="text-danger" />
                                </div>
                                <div class="group_item">
                                    <label class="input_label">Country</label>
                                    <div class="field_wpr" :class="{ 'has-error': errors.country }">
                                        <Field autocomplete="off" name="country" v-model="form.country" :class="{ 'has-error': errors.country }">
                                            <multiselect
                                                v-model="form.country"
                                                :options="countries"
                                                value-prop="code"
                                                label="country"
                                                :searchable="true"
                                                placeholder="Select country"
                                                autocomplete="nofill"
                                            ></multiselect>
                                        </Field>
                                    </div>
                                    <ErrorMessage name="country" class="text-danger" />
                                </div>
                            </div>
                            <div class="form_grp mb-1">
                                <div class="group_item">
                                    <label class="input_label">Mobile Phone Number</label>
                                    <div class="field_wpr">
                                        <Field  name="contactno" autocomplete="nofill" type="text" v-model="form.contactno" placeholder="(814) 555-1212" />
                                    </div>
                                </div>
                            </div>
                            <div class="form_grp mb-1">
                                <div class="group_item">
                                    <label class="input_label">Business Phone Number</label>
                                    <div class="field_wpr">
                                        <Field name="companyno" autocomplete="nofill" type="text" v-model="form.companyno" placeholder="(814) 555-1212" />
                                    </div>
                                </div>
                            </div>
                            <div class="form_grp">
                                <div class="group_item">
                                    <label class="input_label">Website</label>
                                    <div class="field_wpr has_prefix" :class="{ 'has-error': errors.website }">
                                        <Field autocomplete="nofill" name="website" v-model="form.website" type="text" placeholder="www.yoursite.com" />
                                        <span class="prefix">URL</span>
                                    </div>
                                    <small class="url-info">Add https:// or http:// prefix eg https://yourdomain.com</small>
                                    <ErrorMessage name="website" class="text-danger" />
                                </div>
                            </div>
                        </div>
                        <div class="setting_wpr" v-show="tab == 2">
                            <!-- <label for="show-all-contacts" class="switch_option capsule_btn">
                                <h5>User can see all contacts<span>Allow your users to see contacts not assigned to them</span></h5>
                                <input type="checkbox" id="show-all-contacts" v-model="form.allow_contacts" :true-value="1" :false-value="0" hidden>
                                <div><span></span></div>
                            </label> -->
                            <!-- <div class="form_grp mb-1">
                                <div class="group_item">
                                    <label class="input_label">Which user can access your location</label>
                                    <div class="field_wpr has_prefix" :class="{ 'has-error': errors.company_users }">
                                        <Field autocomplete="off" name="company_users" v-model="form.company_users" :class="{ 'has-error': errors.company_users }">
                                            <multiselect
                                                v-model="form.company_users"
                                                :options="organizationUsers"
                                                value-prop="id"
                                                label="full_name"
                                                :searchable="true"
                                                placeholder="Select users"
                                                mode="tags"
                                                autocomplete="nofill"
                                            ></multiselect>
                                        </Field>
                                        <span class="prefix"><i class="fas fa-users"></i></span>
                                    </div>
                                    <ErrorMessage name="company_users" class="text-danger" />
                                </div>
                            </div> -->
                            <div class="form_grp mb-1">
                                <div class="group_item">
                                    <label class="input_label">Send SMS notifications to</label>
                                    <div class="field_wpr">
                                        <Field name="sms_notification_to" autocomplete="nofill" type="text" v-model="form.sms_notification_to" placeholder="(814) 555-1212" />
                                    </div>
                                </div>
                            </div>
                            <div class="form_grp mb-1">
                                <div class="group_item">
                                    <label class="input_label">Timezone of your location</label>
                                    <div class="field_wpr" :class="{ 'has-error': errors.timezone }">
                                        <Field autocomplete="off" name="timezone" v-model="form.timezone" :class="{ 'has-error': errors.timezone }">
                                            <multiselect
                                              v-model="form.timezone"
                                              :options="timezones"
                                              valueProp="code"
                                              label="timezone"
                                              :searchable="true"
                                              autocomplete="nofill"
                                            ></multiselect>
                                        </Field>
                                    </div>
                                    <ErrorMessage name="timezone" class="text-danger" />
                                </div>
                            </div>
                            <div class="form_grp mb-1">
                                <div class="group_item">
                                    <label class="input_label">FROM name on your outgoing emails</label>
                                    <div class="field_wpr" :class="{ 'has-error': errors.email_from }">
                                        <Field autocomplete="nofill" name="email_from" type="text" v-model="form.email_from" placeholder="ex:Synergy Fitness" />
                                    </div>
                                    <ErrorMessage name="email_from" class="text-danger" />
                                </div>
                            </div>
                            <div class="form_grp mb-1">
                                <div class="group_item">
                                    <label class="input_label">REPLY TO email</label>
                                    <div class="field_wpr has_prefix" :class="{ 'has-error': errors.reply_to_email }">
                                        <Field autocomplete="nofill" name="reply_to_email" type="email" v-model="form.reply_to_email" placeholder="john.doe@onboardme.io" />
                                        <span class="prefix"><i class="fas fa-envelope"></i></span>
                                    </div>
                                    <ErrorMessage name="reply_to_email" class="text-danger" />
                                </div>
                            </div>
                            <div class="form_grp mb-1">
                                <div class="group_item">
                                    <label class="input_label">Test/Preview email</label>
                                    <div class="field_wpr has_prefix" :class="{ 'has-error': errors.test_preview_email }">
                                        <Field autocomplete="nofill" name="test_preview_email" type="email" v-model="form.test_preview_email" placeholder="john.doe@onboardme.io" />
                                        <span class="prefix"><i class="fas fa-envelope"></i></span>
                                    </div>
                                    <ErrorMessage name="test_preview_email" class="text-danger" />
                                </div>
                            </div>
                            <div class="form_grp">
                                <div class="group_item">
                                    <label class="input_label">Location Currency</label>
                                    <div class="field_wpr" :class="{ 'has-error': errors.currency }">
                                        <Field autocomplete="off" name="currency" v-model="form.currency" :class="{ 'has-error': errors.currency }">
                                            <multiselect
                                              v-model="form.currency"
                                              :options="currencies"
                                              valueProp="code"
                                              label="currency"
                                              :searchable="true"
                                              autocomplete="nofill"
                                            ></multiselect>
                                        </Field>
                                    </div>
                                    <ErrorMessage name="currency" class="text-danger" />
                                </div>
                            </div>
                        </div>
                        <div class="setting_wpr" v-show="tab == 3">
                                <div class="form_grp">
                                    <div class="group_item">
                                        <label class="input_label">API key</label>
                                        <div class="field_wpr">
                                            <input type="text" readonly v-model="user.access_token" v-if="viewAccessToken">
                                            <input type="text" readonly v-else>
                                            <span class="icon" @click="viewAccessToken = !viewAccessToken">
                                                <i class="far fa-eye-slash" v-if="viewAccessToken"></i>
                                                <i class="far fa-eye" v-else></i>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="form_grp">
                                    <div class="group_item">
                                        <label class="input_label">Email Domain</label>
                                        <div class="field_wpr">
                                            <input type="text" readonly v-model="user.mailgun_email" placeholder="ex:postmaster@onboardme.io">
                                        </div>
                                    </div>
                                </div>
                                <label for="email-subscription" class="switch_option capsule_btn border-0 mt-2 mb-3">
                                    <h5>Mail Subscription<span>Stay up to date with real time email notifications</span></h5>
                                    <input type="checkbox" id="email-subscription" v-model="form.mail_subscription" :true-value="1" :false-value="0" hidden>
                                    <div><span></span></div>
                                </label>

                                <div class="portal-switch" v-if="user.is_thrivecoach_account == 0">
                                    <h5>Portal Contacts See</h5>
                                    <label for="portal-contacts-see" class="switch_option capsule_btn">
                                        <h5>OM Portal</h5>
                                        <input type="checkbox" id="portal-contacts-see" @change="handlePortalToggle()" v-model="form.thrive_portal_enabled" :true-value="1" :false-value="0" hidden>
                                        <div><span></span></div>
                                        <h5 class="ml-2"><span>TC Portal</span></h5>
                                    </label>
                                </div>
                                <div class="portal-switch" v-if="user.is_thrivecoach_account == 0">
                                    <h5>Short URL Domain</h5>
                                    <label for="portal-thrive-domain" class="switch_option capsule_btn">
                                        <h5>OM Domain</h5>
                                        <input type="checkbox" id="portal-thrive-domain" @change="handleThriveDomainToggle()" v-model="form.thrive_short_url_enabled" :true-value="1" :false-value="0" hidden>
                                        <div><span></span></div>
                                        <h5 class="ml-2"><span>TC Domain</span></h5>
                                    </label>
                                </div>
                                <!-- <div class="portal-switch">
                                    <h5>Workout Optin Phone</h5>
                                    <label for="workout-optin-phone-field" class="switch_option capsule_btn">
                                        <input type="checkbox" id="workout-optin-phone-field" @change="handleWorkoutPhoneFieldToggle()" v-model="form.workout_optin_phone_field" :true-value="1" :false-value="0" hidden>
                                        <div><span></span></div>
                                        <h5 class="ml-2"><span></span></h5>
                                    </label>
                                </div> -->
                                <div class="blank_sec">
                                    <img src="@/assets/images/Admin-Settings.svg">
                                </div>
                        </div>
                    </div>
                </div>
                <div class="modal_footer">
                    <button :disabled="userLoader" class="btn cancel_btn" type="button" @click="closeModal()">Cancel</button>
                    <button :disabled="userLoader" class="btn save_btn">
                        <i class="fa fa-spin fa-spinner" v-if="userLoader"></i> {{ userLoader ? 'Saving' : 'Save' }}
                    </button>
                </div>
            </div>
        </div>
    </Form>
</template>

<script>
    import { defineAsyncComponent } from 'vue'
    import { mapState, mapActions } from 'vuex'
    import { Field, Form, ErrorMessage } from 'vee-validate'

    const ImageUpload = defineAsyncComponent(() => import('@/components/image-library/ImageUpload'))

    import Helper from '@/utils/Helper'
    import Swal from 'sweetalert2'
    import * as yup from 'yup'

    export default {
        name: 'Primary Location',

        data () {
            const schema = yup.object({
                fname: yup.string().required().label('The first name'),
                lname: yup.string().required().label('The last name'),
                reply_to_email: yup.string().email().label('The reply to email'),
                test_preview_email: yup.string().email().label('The test/preview email'),
                website: yup.string().url().label('The website'),
            });

            return {
                tab: 1,
                form: {
                    photo: '',
                    fname: '',
                    lname: '',
                    address: '',
                    businessname: '',
                    city: '',
                    state: '',
                    zipcode: '',
                    country: '',
                    timezone: '',
                    contactno: '',
                    companyno: '',
                    sms_notification_to: '',
                    website: '',
                    currency: '',
                    allow_contacts: 0,
                    mail_subscription: 0,
                    company_users: [],
                    email_from: '',
                    reply_to_email: '',
                    test_preview_email: '',
                    thrive_portal_enabled: 0,
                    thrive_short_url_enabled: 0,
                    workout_optin_phone_field: 0,
                    enable_portal_for_all: 0,
                    enable_short_url_for_all: 0,
                },
                schema,
                viewAccessToken: false,
            };
        },

        props: {
            modelValue: Boolean,
        },

        emits: ['update:modelValue'],

        components: {
            Field,
            Form,
            ErrorMessage,
            ImageUpload,
        },

        watch: {
            'form.country' (country) {
                const vm = this;

                vm.getTimezonesByCountry({ country });
            },

            user (user) {
                const vm = this;

                vm.resetForm();
                vm.getOrganizationSuperUsers(user.id);
            },

            organizationSuperUsers (users) {
                const vm = this;

                vm.form.company_users = [];

                users.forEach((user) => {
                    vm.form.company_users.push(user.id);
                });
            },

            modelValue (value) {
                if (value) {
                    const vm = this;
                    const form = vm.$refs['primary-location-form'];
                    document.body.classList.add('modal-open');

                    if (form) {
                        form.resetForm();
                    }

                    vm.resetForm();

                    if (vm.countries.length == 0) {
                        vm.getCountries();
                    }

                    if (vm.organizationUsers.length == 0) {
                        vm.getOrganizationUsers();
                    }

                    vm.getOrganizationSuperUsers(vm.user.id);

                    if (vm.organizationSuperUsers.length) {
                        vm.form.company_users = [];

                        vm.organizationSuperUsers.forEach((user) => {
                            vm.form.company_users.push(user.id);
                        });
                    }
                }else{
                    document.body.classList.remove('modal-open');
                }
            },
        },

        computed: mapState({
            user: state => state.authModule.user,
            countries: state => state.commonModule.countries,
            timezones: state => state.commonModule.timezonesByCountry,
            currencies: state => state.commonModule.currencies,
            organizationUsers: state => state.authModule.organizationUsers,
            organizationSuperUsers: state => state.authModule.organizationSuperUsers,
            userLoader: state => state.authModule.userLoader,
        }),

        mounted () {
            const vm = this;

            vm.resetForm();
        },

        methods: {
            ...mapActions({
                getCountries: 'commonModule/getCountries',
                getTimezonesByCountry: 'commonModule/getTimezonesByCountry',
                getOrganizationUsers: 'authModule/getOrganizationUsers',
                getOrganizationSuperUsers: 'authModule/getOrganizationSuperUsers',
                updateUser: 'authModule/updateUser',
            }),

            closeModal () {
                const vm = this;

                vm.tab = 1;
                vm.$emit('update:modelValue', false);
            },

            resetForm () {
                const vm = this;

                vm.form = {
                    photo: vm.user.photo ? vm.user.photo : '',
                    fname: vm.user.fname ? vm.user.fname : '',
                    lname: vm.user.lname ? vm.user.lname : '',
                    address: vm.user.address ? vm.user.address : '',
                    businessname: vm.user.businessname ? vm.user.businessname : '',
                    city: vm.user.city ? vm.user.city : '',
                    state: vm.user.state ? vm.user.state : '',
                    zipcode: vm.user.zipcode ? vm.user.zipcode : '',
                    country: vm.user.country ? vm.user.country : '',
                    timezone: vm.user.timezone ? vm.user.timezone : '',
                    contactno: vm.user.contactno ? vm.user.contactno : '',
                    companyno: vm.user.companyno ? vm.user.companyno : '',
                    sms_notification_to: vm.user.sms_notification_to ? vm.user.sms_notification_to : '',
                    website: vm.user.website ? vm.user.website : '',
                    currency: vm.user.currency ? vm.user.currency : '',
                    allow_contacts: vm.user.allow_contacts ? vm.user.allow_contacts : 0,
                    mail_subscription: vm.user.mail_subscription ? vm.user.mail_subscription : 0,
                    company_users: [],
                    email_from: vm.user.email_from ? vm.user.email_from : '',
                    reply_to_email: vm.user.reply_to_email ? vm.user.reply_to_email : '',
                    test_preview_email: vm.user.test_preview_email ? vm.user.test_preview_email : vm.user.email,
                    thrive_portal_enabled: vm.user.thrive_portal_enabled ? vm.user.thrive_portal_enabled : 0,
                    thrive_short_url_enabled: vm.user.thrive_short_url_enabled ? vm.user.thrive_short_url_enabled : 0,
                    workout_optin_phone_field: vm.user.workout_optin_phone_field ? vm.user.workout_optin_phone_field : 0,
                    enable_portal_for_all: 0,
                    enable_short_url_for_all: 0,
                };

                const form = vm.$refs['primary-location-form'];

                if (form) {
                    form.setValues(vm.form);
                }
            },

            handleSubmit (params, { setFieldError }) {
                const vm = this;
                const form = vm.form;

                form.setFieldError  = setFieldError;
                form.closeModal     = vm.closeModal;

                vm.updateUser(form);
            },

            handlePortalToggle () {
                const vm = this;

                // let options = Helper.swalConfirmOptions('Are you sure?', 'You are now redirecting all your contacts to your Onboard Me portal. All your links will now redirect to Onboard Me', 'Proceed');

                // if (vm.form.thrive_portal_enabled) {
                //     options = Helper.swalConfirmOptions('Are you sure?', 'You are now redirecting all your contacts to your new Thrive Coach portal. All previously created links will now redirect to Thrive Coach', 'Proceed');
                // }
                // if (vm.user.is_organization_owner) {
                //     options.input = 'checkbox';
                //     options.inputPlaceholder = 'Enable for all locations & coaches';
                // }

                let options = Helper.swalConfirmOptionsWithDenyButton('', 'Update this <b>portal</b> setting for your other locations?', 'Yes', 'No I\'ll do it manually');

                options.confirmButtonColor  = '#2f80ed';
                options.denyButtonColor     = '#F2A31D';
                options.showCancelButton    = true;

                options.preConfirm = function () {
                                        vm.form.enable_portal_for_all = 1;

                                        return vm.updateUser(vm.form);
                                    };

                options.preDeny = function () {
                                    vm.form.enable_portal_for_all = 0;

                                    return vm.updateUser(vm.form);
                                };

                Swal.fire(options).then((result) => {
                    if (result.isDismissed) {
                        vm.form.thrive_portal_enabled = vm.form.thrive_portal_enabled ? 0 : 1;
                    }
                });
            },

            handleThriveDomainToggle () {
                const vm = this;

                // let options = Helper.swalConfirmOptions('Are you sure?', 'You are now enabling Onboard Me short links.', 'Proceed');

                // if (vm.form.thrive_short_url_enabled) {
                //     options = Helper.swalConfirmOptions('Are you sure?', 'You are now enabling Onboard Me short links.', 'Proceed');
                // }

                // if (vm.user.is_organization_owner) {
                //     options.input = 'checkbox';
                //     options.inputPlaceholder = 'Enable for all locations & coaches';
                // }

                let options = Helper.swalConfirmOptionsWithDenyButton('', 'Update this <b>link</b> setting for your other locations?', 'Yes', 'No I\'ll do it manually');

                options.confirmButtonColor  = '#2f80ed';
                options.denyButtonColor     = '#F2A31D';
                options.showCancelButton    = true;

                options.preConfirm = function () {
                                        vm.form.enable_short_url_for_all = 1;

                                        return vm.updateUser(vm.form);
                                    };

                options.preDeny = function () {
                                    vm.form.enable_short_url_for_all = 0;

                                    return vm.updateUser(vm.form);
                                };

                Swal.fire(options).then((result) => {
                    if (result.isDismissed) {
                        vm.form.thrive_short_url_enabled = vm.form.thrive_short_url_enabled ? 0 : 1;
                    }
                });
            },

            handleWorkoutPhoneFieldToggle () {
                const vm = this;

                vm.updateUser(vm.form);
            },
        },
    }
</script>

<style scoped>
    :deep(.upload_image .tab_content) {
        height: auto;
    }

    .portal-switch{
        padding: 10px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 4px;
    }

    .portal-switch > h5 {
        font-size: 16px;
        line-height: 20px;
        font-weight: 500;
        color: #121525;
        margin-right: 15px;
    }

    /* .portal-switch .capsule_btn {
        max-width: 190px;
    }

    .portal-switch .capsule_btn h5 {
        font-size: 12px;
        color: #6a6a6a;
        max-width: 170px;
        margin-right: 10px;
    } */
</style>
